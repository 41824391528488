<template>
  <el-tabs type="border-card" @tab-click="handleClick">
    <el-tab-pane label="企业所得税">
      <div class="right_box">
        <el-button type="primary" @click="printingNew()" size="small">导出</el-button>
        <el-button size="small" @click="download()" type="success">打印</el-button>
      </div>
      <mainTable ref="maintable" :startAccountPeriod="startAccountPeriod" :comId="comId" :qysdssType="qysdssType"></mainTable>
    </el-tab-pane>
    <el-tab-pane label="资产加速折旧、摊销（扣除）优惠明细表">
      <fbOne ref="fbOne" :startAccountPeriod="startAccountPeriod" :comId="comId"></fbOne>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import mainTable from "./components/mainTable.vue"
import fbOne from "./components/fb1.vue"
import { report ,exportReport} from "@/api/printSet"

export default {
  name:'qysds',
  components:{
    mainTable,
    fbOne,
  },
  data() {
    return {
      startAccountPeriod:'',
      comId:0,
      type:'',
      qysdssType:0,
      param:{
        comIds:[this.$store.getters['user/comInfo'].comId],
        period:this.$store.getters['user/comInfo'].period,
        bookNames:['gs_tax_quarter']
      }
    }
  },
  created(){
    if(this.$store.getters["commons/params"].type){
      this.type = this.$store.getters["commons/params"].type
      this.startAccountPeriod = this.$store.getters["commons/params"].startAccountPeriod
      this.comId = this.$store.getters["commons/params"].comId
      this.qysdssType = this.$store.getters["commons/params"].qysdsType
    }
  },
  methods:{
    handleClick(tab){
      if(tab.props.label == '企业所得税'){
        this.$refs.maintable.getList()
      }else if(tab.props.label == '资产加速折旧、摊销（扣除）优惠明细表'){
        this.$refs.fbOne.getList()
      }
    },
    printingNew(){
      report(this.param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    download(){
      exportReport(this.param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.right_box {
  // float: right;
  width:25%;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 16px;
  position: absolute;
  top: 10px;
  right: 3px;
}
</style>