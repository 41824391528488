<template>
  <div class="big_box4">
    <div class="top_title3">
      <h4>资产加速折旧、摊销（扣除）优惠明细表</h4>
      <h5>一般纳税人、小规模纳税人适用</h5>
      <div class="bottom_font clearfix">
        <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
        <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
        <h6 class="right">
          <p>单位：元</p>
          <p>纳税人识别号：<span>{{taxNo}}</span></p>
        </h6>
      </div>
    </div>
    <table class="content" cellspacing="0">
      <tr class="top_bg">
        <td rowspan="3" class="center" style="width:4%">行次</td>
        <td rowspan="3" class="center" style="width:20%">项目</td>
        <td rowspan="2" class="center" style="width:10%">本年享受优惠的资产原值</td>
        <td colspan="5" class="center" style="width:60%">本年累计折旧（扣除）金额</td>
      </tr>
      <tr class="top_bg">
        <td class="center" style="width:12%">账载折旧/摊销金额</td>
        <td class="center" style="width:12%">按照税收一般规定计算的折旧/摊销金额</td>
        <td class="center" style="width:12%">享受加速折旧优惠计算的折旧/摊销金额</td>
        <td class="center" style="width:12%">纳税调减金额</td>
        <td class="center" style="width:12%">享受加速折旧/摊销优惠金额</td>
      </tr>
      <tr class="top_bg">
        <td class="center">1</td>
        <td class="center">2</td>
        <td class="center">3</td>
        <td class="center">4</td>
        <td class="center">5</td>
        <td class="center">6（4-3）</td>
      </tr>
    </table>
    <div class="fixed_box_table">
      <table class="content" cellspacing="0">
        <tr>
          <td class="center" style="width:4%">1</td>
          <td style="width:20%">一、加速折旧、摊销（不含一次性扣除，1.1+1.2+...）</td>
          <qzf-td style="width:10%" :disabled="false" v-model:amount="assetsArr[0].zcyz" @change="changeList()"></qzf-td>
          <qzf-td style="width:12%" :disabled="false" v-model:amount="assetsArr[0].zzzjjeLj" @change="changeList()"></qzf-td>
          <qzf-td style="width:12%" :disabled="false" v-model:amount="assetsArr[0].azssybgdjsdzjjeLj" @change="changeList()"></qzf-td>
          <qzf-td style="width:12%" :disabled="false" v-model:amount="assetsArr[0].xsjszjyhjsdzjjeLj" @change="changeList()"></qzf-td>
          <qzf-td style="width:12%" :disabled="false" v-model:amount="assetsArr[0].nstjjeLj" @change="changeList()"></qzf-td>
          <qzf-td style="width:12%" :disabled="false" v-model:amount="assetsArr[0].xsjszjyhjeLj" @change="changeList()"></qzf-td>
        </tr>
        <tr>
          <td class="center" style="width:4%">2</td>
          <td style="width:20%">二、一次性扣除（2.1+2.2+...）</td>
          <qzf-td style="width:10%" :disabled="false" v-model:amount="assetsArr[1].zcyz" @change="changeList()"></qzf-td>
          <qzf-td style="width:12%" :disabled="false" v-model:amount="assetsArr[1].zzzjjeLj" @change="changeList()"></qzf-td>
          <qzf-td style="width:12%" :disabled="false" v-model:amount="assetsArr[1].azssybgdjsdzjjeLj" @change="changeList()"></qzf-td>
          <qzf-td style="width:12%" :disabled="false" v-model:amount="assetsArr[1].xsjszjyhjsdzjjeLj" @change="changeList()"></qzf-td>
          <qzf-td style="width:12%" :disabled="false" v-model:amount="assetsArr[1].nstjjeLj" @change="changeList()"></qzf-td>
          <qzf-td style="width:12%" :disabled="false" v-model:amount="assetsArr[1].xsjszjyhjeLj" @change="changeList()"></qzf-td>
        </tr>
      </table>
    </div>
    <div class="save_box">
      <el-button type="primary" @click="saveFb4()" size="small" plain v-if="this.startAccountPeriod">保存</el-button>
      <qzf-button type="primary" @success="saveFb4()" size="small" plain v-else>保存</qzf-button>
    </div>
  </div>
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name:'fb1',
  props: {
    startAccountPeriod:{
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
  },
  data(){
    return{
      assetsArr:[{},{}],
      comName:'',
      durationOfTaxTime:'',
      taxNo:'',
    }
  },
  methods:{
    changeList(){

    },
    getList(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_tax_quarter',
          period:this.startAccountPeriod,
          itemName:'fb4',
          comId:this.comId
        }
        gsInfo(param).then(res=>{
          if(res.data.data.info && res.data.data.info.length != 0){
            this.assetsArr = res.data.data.info
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.durationOfTaxTime = res.data.data.time
          }
        })
      }else{
        gsInfo({tableName: 'gs_tax_quarter',itemName:'fb4'}).then(res=>{
          if(res.data.data.info && res.data.data.info.length != 0){
            this.assetsArr = res.data.data.info
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.durationOfTaxTime = res.data.data.time
          }
        })
      }
    },
    saveFb4(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'gs_tax_quarter',
          itemName:'fb4',
          items:this.assetsArr,
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }else{
        let param = {
          tableName:'gs_tax_quarter',
          itemName:'fb4',
          items:this.assetsArr
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
}
.center{
  text-align: center;
  color: #333 !important;
}
.weight{
  font-size: 14px !important;
  font-weight: 600;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.left_k{
  text-indent: 41px;
}
.left_k{
  text-indent: 2em;
}
.left_indent{
  text-indent: 4em;
}
.left_big_indent{
  text-indent: 8em;
}
.border_none .el-select{
  width: 100% !important;
}
.border_none .el-input__inner{
  width: 100% !important;
  background-color: none !important;
}
.input_width input{
  width:60px !important;
  margin: 0 5px;
}
.gary_box{
  background: #f8f8f8;
  text-align: right;
}
.blue_box{
  background: #fff ;
}
.el-radio+.el-radio {
    margin-left: 7px;
}
.el-radio__label{
  font-size: 14px !important;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.app-container{
  position: relative;
  .right_btns{
    position: absolute;
    top: 20px;
    right: 40px;
    z-index: 99;
  }
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 28px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 14px;
    p{
      display: inline-block;
      color: #409EFF;
      cursor: pointer;
    }
  }
  input{
    width: 100%;
    // line-height: 28px;
    // padding: 0 6px;
    font-size: 14px;
    text-align: right;
    padding-right: 12px;
  }
}
.content2{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 26px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 26px;
    padding:0 6px;
    color: #666;
    font-size: 14px;
    p{
      display: inline-block;
      color: #409EFF;
      cursor: pointer;
    }
  }
  input{
    width: 100%;
    line-height: 26px;
    // padding: 0 6px;
    font-size: 14px;
    text-align: right;
    padding-right:20px;
  }
}
.top_title3{
  width: 100%;
  margin: 0 auto;
  h4{
    font-size: 20px;
    color: #333;
    font-weight: 600;
    text-align: center;
  }
  h5{
    font-size: 16px;
    color: #333;
    font-weight: normal;
    text-align: center;
    margin-top: 10px;
  }
  .bottom_font{
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    h6{
      float: left;
      width: 33.33%;
      font-size: 14px;
      color: #333;
      font-weight: normal;
    }
    h6:nth-child(1){
      padding-top: 10px;
    }
    h6:nth-child(2  ){
      padding-top: 10px;
    }
  }
}

.big_box4{
  width: 98%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
}
</style>
